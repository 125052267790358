import React, { useState, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
// import { Skeleton } from '@mui/material'
import { makeStyles } from '@mui/material/styles';
import clsx from 'clsx';
import { ComposedChart, LineChart, Line, Scatter, ScatterChart, ReferenceLine, XAxis, YAxis, ZAxis, ResponsiveContainer, Tooltip, ReferenceArea, Bar, BarChart, Area } from 'recharts';
import { Text } from '@visx/text';
// import { TuneDot } from './TuneDot'
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CloseIcon from '@mui/icons-material/Close';
const PREFIX = 'DialChart';

const classes = {
  root: `${PREFIX}-root`,
  muteButton: `${PREFIX}-muteButton`,
  buttonReasonModal: `${PREFIX}-buttonReasonModal`,
  flexView: `${PREFIX}-flexView`,
  dialChart: `${PREFIX}-dialChart`,
  smallChart: `${PREFIX}-smallChart`
};

const DialChartRoot = styled('div')({
  boxSizing: 'border-box',
  height: '100%',
  padding: '1vh 4vw 0 0',
  position: 'relative',
  width: 'auto',
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white',
    height: '48px',
    width: '48px',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'zoom-out'
  },
  [`& .${classes.buttonReasonModal}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 'fit-content',
    minWidth: '25rem',
    background: 'rgba(255,255,255,0.5)',
    margin: '0 0 90px 76px',
    backdropFilter: 'blur(6px)',
  },
  [`& .${classes.flexView}`]: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 0,
    paddingBottom: 20
  },
  [`& .${classes.dialChart}`]: {
    userSelect: 'none'
  },
  [`& .${classes.smallChart}`]: {
    backgroundColor: 'rgba(0,0,0,0.25)'
  }
});

// import { useRechartToPng } from "recharts-to-png";

const groupBy = require('lodash/groupBy')
const cloneDeep = require('lodash/cloneDeep');

// function useTraceUpdate(props) {
//   const prev = useRef(props);
//   useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log('Changed props:', changedProps);
//     }
//     prev.current = props;
//   });
// }

const formatTime = (time, padAll) => {
  const s = `${Math.floor(time) % 60}`.padStart(2, '0')
  const m = Math.floor(time / 60) % 60
  const h = Math.floor(time / 60 / 60)
  return h || padAll ? `${padAll ? h.toString().padStart(2, '0') : h}:${m.toString().padStart(2, '0')}:${s}` : (m ? `${m.toString().padStart(2, '0')}:${s}` : ` :${s}`)
}

const formatMinutes = (time) => {
  return Math.floor(time / 60)
}

const renderTriangleDown = (props) => {
  return (
    <polygon transform={`translate(${props.x}, ${props.y})`} points={`${props.width / 2},${props.height - props.height / 5} ${props.width - props.width / 5},${props.height / 5} ${props.width / 5},${props.height / 5}`} fill={props.fill} />
  )
}
const renderCircle = (props) => {
  return (
    <circle transform={`translate(0, ${props.width / 2})`} cx={props.x} cy={props.y} r={props.height / 3} fill={props.fill} />
  )
}
const renderSquare = (props) => {
  return (
    <rect transform={`translate(0, ${props.height / 4})`} x={props.x} y={props.y} height={props.height / 2} width={props.width / 2} fill={props.fill} />
  )
}

const renderShapeMap = {
  triangleDown: renderTriangleDown,
  square: renderSquare,
  circle: renderCircle
}

export const DialChart = React.memo((props) => {
  // useTraceUpdate(props)
  const { segmentsMap, currentSegment, currentSegmentGroup, currentSymbol, singleView, chartRef, buttonChartRef, loaded, scoreBase, chartData, buttons, customButtons, chartDomain, onDomainChange, onHover, lineType, lineWidth, lineColor, videoMarkerColor, videoMarkerWidth, chartStart, chartEnd, isLive, videoMarkers, dialMode, dataKeys, symbolKeys, showSegmentLine, showMinutes, showMarkerLabels, showSegmentLabels, scorePrecision, graphType, isChartViewFull, count } = props

  // const theme = useTheme()

  const [buttonReasonData, setButtonReasonData] = useState()
  const [refAreaLeft, setRefAreaLeft] = useState()
  const [refAreaRight, setRefAreaRight] = useState()
  const [yMax, setYMax] = useState(scoreBase);
  // const [plotSelected, setPlotSelected] = useState();
  // const [tickInterval, setTickInterval] = useState(chartEnd === 'auto' ? 0 : Math.ceil(chartEnd) > 3600 ? 5 : Math.ceil(chartEnd) < 600 ? 1 : 2)
  const tickInterval = (chartEnd === 'auto' ? 0 : Math.ceil(chartEnd) > 3600 ? 5 : Math.ceil(chartEnd) < 600 ? 1 : 2)
  const domainMin = chartDomain?.[0] || chartStart
  const domainMax = chartDomain?.[1] || chartEnd

  const zoomIn = useCallback(() => {
    if (refAreaLeft === refAreaRight || (isNaN(refAreaRight) & !refAreaRight)) {
      setRefAreaLeft()
      setRefAreaRight()
    } else {
      const left = (+refAreaLeft > +refAreaRight) ? refAreaRight : refAreaLeft
      const right = (+refAreaLeft > +refAreaRight) ? refAreaLeft : refAreaRight
      setRefAreaLeft()
      setRefAreaRight()
      if (onDomainChange) onDomainChange([left, right])
    }

  }, [onDomainChange, refAreaLeft, refAreaRight])

  const zoomOut = useCallback((event) => {
    event.currentTarget.blur()
    if (onDomainChange) onDomainChange([])
  }, [onDomainChange])

  const yTicks = useCallback((yMax) => {
    let incrVal = 5;
    if (yMax <= 10) {
      incrVal = 1;
    } else if (yMax <= 50) {
      incrVal = 5;
    } else if (yMax <= 100) {
      incrVal = 10;
    } else if (yMax <= 200) {
      incrVal = 20;
    } else if (yMax <= 500) {
      incrVal = 50;
    } else {
      incrVal = 100
    };
    // incrVal = Math.ceil(yMax/10) // Math.max(Math.ceil(yMax/10), 100)
    const resArr = [];
    for (let i = incrVal; i <= yMax; i += incrVal) {
      resArr.push(i);
    }
    return resArr.length;
  }, [])

  const yDomain = useMemo(() => {
    if (dialMode === 'symbol' && graphType === 'line') {
      return [0, dataMax => {
        let round = (Math.ceil(dataMax / 5) * 5) + 5;
        setYMax(round);
        return round;
      }]
    } else if (dialMode === 'score') {
      setYMax(scoreBase)
      return [0, scoreBase]
    } else {
      setYMax(1);
      return [0, 1]
    }
  }, [dialMode, graphType, setYMax, chartData, scoreBase])

  const chartTicks = useMemo(() => {
    return Array.from(Array(Math.ceil(chartEnd === 'auto' ? 5 : chartEnd) + 1).keys())
  }, [chartEnd])

  const symbolView = useMemo(() => {
    return graphType === 'bar100' || graphType === 'area100' ? 'percent' : 'count'
  }, [graphType])

  const setZoomLeft = useCallback((e) => e && requestAnimationFrame(() => setRefAreaLeft(e.activeLabel)), [])

  const setZoomRight = useCallback((e) => e && requestAnimationFrame(() => setRefAreaRight(e.activeLabel)), [])

  const tooltipLabel = useCallback((value) => {
    const time = formatTime(value, true)
    return dialMode === 'score' ? time : `${time} - ${singleView ? currentSegment : (symbolKeys.find(x => x.emoji === currentSymbol)?.tooltip || currentSymbol)}`
  }, [dialMode, singleView, currentSegment, currentSymbol, symbolKeys])

  const tooltipFormatter = useCallback((value, name, props) => {
    return [(Math.round((value * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)), name[0].toUpperCase() + name.slice(1)]
  }, [scorePrecision, dialMode])

  const formatSecToTime = useCallback((sec) => formatTime(sec, true), [])

  const setCurrentScene = useCallback((e) => {
    if (onHover) onHover(!!e.isTooltipActive, e.activeLabel)
  }, [onHover])

  const showButtonReasons = useCallback(({ payload }) => {
    if (payload?.reasons?.length) setButtonReasonData(prev => (prev?.videoTime === payload.videoTime && prev?.dataKey === payload.dataKey) ? null : payload)
  }, [])

  const buttonTooltip = useCallback((props) => {
    return renderTooltip(props, customButtons)
  }, [customButtons])

  const renderChart = (includeTuneOuts, { totals, currentButtons, count, virtualDataKeys, liveChartData }, { height = 490, width = 1100 }) => {
    const chartInfo = dialMode === 'symbol' ? (singleView ?
      symbolKeys.filter(i => showSegmentLine[currentSegment][i.emoji]).map(sym => {
        const segTuneOuts = (currentButtons[currentSegment] && currentButtons[currentSegment].reduce((p, c) => p + c.total, 0)) || 0
        const allTuneOuts = (currentButtons['all'] && currentButtons['all'].reduce((p, c) => p + c.total, 0)) || 0
        return {
          label: sym.tooltip,
          total: liveChartData[currentSegment][sym.emoji].total,
          tuneOuts: segTuneOuts,
          count: (virtualDataKeys[currentSegment] && virtualDataKeys[currentSegment].dataKey === 'button' && virtualDataKeys[currentSegment].dataValue === 'R') ? (virtualDataKeys[currentSegment].condition === '!==' ? (count[currentSegment] - allTuneOuts) : segTuneOuts) : (count[currentSegment] || 0),
          color: lineColor[sym.emoji] || lineColor['default']
        }
      })
      : segmentsMap[currentSegmentGroup].filter(i => showSegmentLine[i][currentSymbol]).map(segment => {
        const segTuneOuts = (currentButtons[segment]?.['R'] && currentButtons[segment]['R'].reduce((p, c) => p + c.total, 0)) || 0
        const allTuneOuts = (currentButtons['all']?.['R'] && currentButtons['all']['R'].reduce((p, c) => p + c.total, 0)) || 0
        return {
          label: segment[0].toUpperCase() + segment.slice(1),
          total: liveChartData[segment][currentSymbol].total,
          tuneOuts: segTuneOuts,
          count: (virtualDataKeys[segment] && virtualDataKeys[segment].dataKey === 'button' && virtualDataKeys[segment].dataValue === 'R') ? (virtualDataKeys[segment].condition === '!==' ? (count[segment] - allTuneOuts) : segTuneOuts) : (count[segment] || 0),
          color: lineColor[segment] || lineColor['default']
        }
      })
    ) : dataKeys.filter(segment => !!showSegmentLine[segment]).map((segment, i) => {
      const segTuneOuts = (currentButtons[segment]?.['R'] && currentButtons[segment]['R'].reduce((p, c) => p + c.total, 0)) || 0
      const allTuneOuts = (currentButtons['all']?.['R'] && currentButtons['all']['R'].reduce((p, c) => p + c.total, 0)) || 0
      return {
        label: segment[0].toUpperCase() + segment.slice(1),
        // total: totals[segment] || 0,
        total: (Math.round(((totals[segment] || 0) * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)),
        tuneOuts: segTuneOuts,
        count: (virtualDataKeys[segment] && virtualDataKeys[segment].dataKey === 'button' && virtualDataKeys[segment].dataValue === 'R') ? (virtualDataKeys[segment].condition === '!==' ? (count[segment] - allTuneOuts) : segTuneOuts) : (count[segment] || 0),
        color: lineColor[segment] || lineColor['default']
      }
    })
    return <>
      <LineChart id="graph-composed-chart" syncId="dialChart" height={includeTuneOuts ? height - 150 : height - 100} width={width} style={{ userSelect: 'none', overflow: 'visible' }} data={chartData} >
        {showSegmentLabels && <ReferenceLine x={domainMin} stroke="transparent" label={{ value: chartInfo, dialMode, currentSegment, currentSegmentGroup, currentSymbol, singleView, fontSize: 18, content: InfoLabel }} />}
        {dialMode === 'score' && <ReferenceLine y={yMax / 2} stroke="#000" />}
        <YAxis style={{ fontFamily: 'Roboto, sans-serif' }} stroke="#000" allowDecimals={false} domain={yDomain} tickCount={21} name="Score" />
        <XAxis style={{ fontFamily: 'Roboto, sans-serif' }} tickMargin={30} padding={{ right: 20 }} angle={-70} allowDataOverflow={true} stroke="#000" dataKey="videoTime" type="number" name="Time" ticks={chartTicks} tickFormatter={formatSecToTime} domain={[domainMin - 0, domainMax - 0]} />
        {videoMarkers && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceLine key={`videomarker:${markerTime}-${i}`} segment={[{ x: markerTime, y: 2 / 5 * yMax }, { x: markerTime, y: 3 / 5 * yMax }]} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} label={{ value: showMarkerLabels ? videoMarkers[markerTime] : '', fill: '#000', fontSize: 14, content: ReferenceLabel }} />)}
        {dataKeys.map((segment, i) => dialMode === 'score' ?
          showSegmentLine[segment] && <Line key={`segmentline-${segment}`} connectNulls={true} dot={false} type={lineType} dataKey={segment} stroke={lineColor[segment] || lineColor['default']} strokeWidth={lineWidth[segment] || lineWidth['default']} isAnimationActive={false} /> :
          symbolKeys.map((symbol, i) =>
            (showSegmentLine[segment][symbol.emoji] && (singleView || segmentsMap[currentSegmentGroup].includes(segment)) && <Line key={`segmentline-${segment}-${symbol.emoji}`} connectNulls={false} dot={false} type={lineType} dataKey={`${segment}['${symbol.emoji}'][${symbolView}]`} stroke={singleView ? lineColor[symbol.emoji] : lineColor[segment]} strokeWidth={singleView ? lineWidth[symbol.emoji] || lineWidth['default'] : lineWidth[segment] || lineWidth['default']} isAnimationActive={false} />))
        )}
      </LineChart>
      {includeTuneOuts && <ScatterChart syncId="dialChart" height={100} width={width}>
        <XAxis allowDataOverflow={true} stroke="#000" dataKey="videoTime" type="number" tick={false} axisLine={true} tickLine={false} domain={[() => domainMin - 0, () => domainMax - 0]} interval="preserveStartEnd" />
        <YAxis type="number" dataKey="index" domain={[0, 10]} interval="preserveStartEnd" tick={false} tickLine={false} axisLine={false}  /*label={{ value: 'TUNE OUTS', position: 'insideLeft', fontSize: '75%' }}*/ />
        <ZAxis type="number" dataKey="total" domain={[0, 10]} range={[500, 500]} />
        {dialMode === 'symbol' ? dataKeys.map((segment, i) => (singleView ? segment === currentSegment : (showSegmentLine[segment][currentSymbol] && segmentsMap[currentSegmentGroup].includes(segment))) && buttons[segment] && Object.entries(buttons[segment]).map(([button, data]) => (<>
          <Scatter key={`scatter-${segment}-${button}`} data={data} name="HELLO" fill={singleView ? lineColor['tuneout'] : lineColor[segment] || lineColor['default']} isAnimationActive={false} shape={renderShapeMap[customButtons.get(button)?.shape]} />
        </>))) : dataKeys.map((segment, i) => showSegmentLine[segment] && buttons[segment] && Object.entries(buttons[segment]).map(([button, data]) => (<>
          <Scatter key={`scatter-${segment}-${button}`} data={data} name="HELLO" fill={lineColor[segment] || lineColor['default']} isAnimationActive={false} shape={renderShapeMap[customButtons.get(button)?.shape]} />
        </>)))}
      </ScatterChart>}
    </>
  }
  chartRef.current = renderChart

  const renderButtons = ({ totals, currentButtons, count, virtualDataKeys }, { height = 490, width = 1100 }) => {
    const start = {
      videoTime: 0
    }
    const end = {
      videoTime: chartEnd
    }
    const chartInfo = dataKeys.filter(segment => !!showSegmentLine[segment]).map((segment, i) => {
      start[segment] = 0
      end[segment] = ((buttons[segment]?.['R'] && buttons[segment]['R'].reduce((p, c) => p + c.total, 0)) || 0) / count[segment] * -1
      const segTuneOuts = (currentButtons[segment]?.['R'] && currentButtons[segment]['R'].reduce((p, c) => p + c.total, 0)) || 0
      const allTuneOuts = (currentButtons['all']?.['R'] && currentButtons['all']['R'].reduce((p, c) => p + c.total, 0)) || 0
      return {
        label: segment[0].toUpperCase() + segment.slice(1),
        total: totals[segment]?.toFixed(2) || 0,
        tuneOuts: segTuneOuts,
        count: (virtualDataKeys[segment] && virtualDataKeys[segment].dataKey === 'button' && virtualDataKeys[segment].dataValue === 'R') ? (virtualDataKeys[segment].condition === '!==' ? (count[segment] - allTuneOuts) : segTuneOuts) : (count[segment] || 0),
        color: lineColor[segment] || lineColor['default']
      }
    })
    const tuneOutAcc = {}
    Object.keys(buttons).forEach(k => {
      tuneOutAcc[k] = cloneDeep(buttons[k]['R']).map((z, i, a) => {
        z.total = z.total + ((a[i - 1] && a[i - 1].total) || 0)
        return z
      }).map((z, i, a) => {
        z.total = z.total / count[k]
        return z
      })
    })
    const tuneOutData = Object.entries(groupBy(Object.values(tuneOutAcc).flat(), 'videoTime')).map(([vt, y]) => {
      const current = {
        videoTime: vt
      }
      y.forEach((z) => {
        current[z.dataKey] = z.total * -1
      })
      return current
    })
    tuneOutData.unshift(start)
    tuneOutData.push(end)
    return <>
      <LineChart syncId="dialChart" height={height} width={width} style={{ userSelect: 'none' }} data={tuneOutData} >
        {showSegmentLabels && <ReferenceLine x={domainMin} stroke="transparent" label={{ value: chartInfo, fontSize: 18, content: InfoLabel }} />}
        <ReferenceLine y={0} stroke="#000" />
        <YAxis style={{ fontFamily: 'Roboto, sans-serif' }} allowDataOverflow={false} stroke="#000" domain={[-1, 1]} tickFormatter={(decimal) => `${(decimal * 100).toFixed(0)}%`} name="Percent" tickCount={42} interval={0} />
        <XAxis style={{ fontFamily: 'Roboto, sans-serif' }} allowDataOverflow={true} stroke="#000" dataKey="videoTime" type="number" name="Time" ticks={chartTicks} tickFormatter={showMinutes ? formatMinutes : formatTime} domain={[domainMin - 0, domainMax - 0]} interval={showMinutes ? (60 * tickInterval - 1) : "preserveStartEnd"} />
        {videoMarkers && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceLine key={`videomarker:${markerTime}-${i}`} segment={[{ x: markerTime, y: -1 / 5 }, { x: markerTime, y: 1 / 5 }]} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} label={{ value: showMarkerLabels ? videoMarkers[markerTime] : '', fill: '#000', fontSize: 14, content: ReferenceLabel }} />)}
        {dataKeys.map((segment, i) => showSegmentLine[segment] && <Line key={`segmentline-${segment}`} connectNulls={true} dot={false} type={'stepBefore'} dataKey={segment} stroke={lineColor[segment] || lineColor['default']} strokeWidth={lineWidth[segment] || lineWidth['default']} isAnimationActive={false} />)}
      </LineChart>
    </>
  }
  buttonChartRef.current = renderButtons

  // const renderEmojiTooltip = ({ active, payload, label }) => {
  //   if (plotSelected) {
  //     let selected = payload.find(i => i.name === `${plotSelected.segment}['${plotSelected.symbol.emoji}']`)
  //     return <div style={{
  //       border: `2px solid ${(graphType === 'line' ? selected?.stroke : selected?.fill) || 'black'}`,
  //       padding: '5px 5px 0px 5px', boxShadow: 'rgb(85,85,85,.3) 0px 0px 0 .75px',
  //       backgroundColor: 'rgb(85,85,85,.3)'
  //     }}>
  //       <div style={{ fontWeight: 'bold', color: 'white' }}>
  //         {singleView ? <p style={{ marginTop: 0, marginBottom: 5 }}>{plotSelected.symbol.tooltip}</p> : <p style={{ marginTop: 0 }}>{plotSelected.segment}</p>}
  //         <div style={{ margin: 2 }}>Time: <span style={{ fontWeight: 'normal', marginLeft: 15 }}>{formatSecToTime(label)}</span></div>
  //         {graphType === 'bar100' || graphType === 'area100' ?
  //           <div style={{ margin: 2 }}>Percentage: <span style={{ fontWeight: 'normal', marginLeft: 15 }}>{(selected?.value)?.toFixed(2)}%</span></div>
  //           :
  //           <div style={{ margin: 2 }}>Click Count: <span style={{ fontWeight: 'normal', marginLeft: 15 }}>{selected?.value}</span></div>}
  //       </div></div>
  //   } else return null
  // }
  if (!loaded) {
    return (
      // <Skeleton component='div' variant="rect" className="loading-box">
      //   <div className="loading-container">
      //     <Typography className="loading-text">Syncing Data...</Typography>
      //   </div>
      // </Skeleton>
      <div className="loading-box">
        <div className="loading-container">
          <Typography className="loading-text">Syncing Data to Video<span className="loading-ellipsis" /></Typography>
        </div>
      </div>
    )
  } else {
    return (
      <DialChartRoot>
        <div className={clsx('chart-box', { [classes.flexView]: !isChartViewFull })} onMouseLeave={setCurrentScene}>
          <ResponsiveContainer height={isChartViewFull ? '90%' : '30%'}>
            <ComposedChart id="graph-composed-chart" syncId="dialChart" className={clsx(classes.dialChart, { [classes.smallChart]: !isChartViewFull })} data={chartData} onMouseDown={setZoomLeft} onMouseMove={refAreaLeft ? setZoomRight : setCurrentScene} onMouseUp={zoomIn} >
              {dialMode === 'score' && isChartViewFull && <ReferenceLine y={yMax / 2} stroke="#888" strokeDasharray="3 3" />}
              <YAxis style={{ fontFamily: 'Roboto, sans-serif' }} stroke="#aaa" allowDecimals={false} domain={yDomain} tickCount={yTicks(yMax) + 1} name="Score" />
              <XAxis style={{ fontFamily: 'Roboto, sans-serif' }} tickMargin={30} padding={{ right: 20 }} angle={-70} allowDataOverflow={true} stroke="#aaa" dataKey="videoTime" type="number" name="Time" domain={[domainMin - 0, Number.isFinite(domainMax) ? domainMax - 0 : 'auto']} tickFormatter={formatSecToTime} ticks={isLive ? undefined : chartTicks} tickCount={isLive ? chartTicks.length : undefined} interval="preserveStartEnd" />
              {/* <XAxis style={{ fontFamily: 'Roboto, sans-serif' }} allowDataOverflow={true} stroke="#aaa" dataKey="videoTime" type="number" name="Time" tickFormatter={showMinutes ? formatMinutes : formatTime} domain={[domainMin - 0, Number.isFinite(domainMax) ? domainMax - 0  : 'auto']} interval={showMinutes ? (60 * tickInterval - 1) : "preserveStartEnd"} ticks={chartTicks} /> */}
              {/* {videoMarkers && videoMarkers.map((marker, i, a) => <ReferenceLine x={(marker.markerHour * 3600) + (marker.markerMinute * 60) + marker.markerSecond} stroke="yellow" label={{ position: 'bottom',  value: marker.markerName, fill: '#eee', fontSize: 14, content: ReferenceLabel }} stroke="yellow" />)} */}
              {/* {videoMarkers && videoMarkers.map((marker, i, a) => <ReferenceArea x1={i ? (a[i-1].markerHour * 3600) + (a[i-1].markerMinute * 60) + a[i-1].markerSecond : 0} x2={(marker.markerHour * 3600) + (marker.markerMinute * 60) + marker.markerSecond} stroke="yellow" label={renderCustomBarLabel} shape={<CustomReferenceArea />} />)} */}
              {/* {videoMarkers && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceArea key={`videomarker:${markerTime}`} x1={markerTime} x2={i !== a.length - 1 ? a[i + i] : chartEnd} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} shape={<CustomReferenceArea />} />)} */}
              {/* {videoMarkers && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceArea key={`videomarker:${markerTime}`} x1={markerTime} x2={i !== a.length - 1 ? a[i + i] : chartEnd} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} shape={<CustomReferenceArea />}  label={{ value: (markerTime >= domainMin && markerTime <= domainMax) ? videoMarkers[markerTime], fill: '#fff', fontSize: 14, content: ReferenceLabel }} />)} */}
              {/* {videoMarkers && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceLine key={`videomarker:${markerTime}`} x={markerTime} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} />)} */}
              {videoMarkers && isChartViewFull && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceLine key={`videomarker:${markerTime}`} segment={[{ x: markerTime, y: 2 / 5 * yMax }, { x: markerTime, y: 3 / 5 * yMax }]} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} />)}
              {/* {videoMarkers && Object.keys(videoMarkers).map((markerTime, i, a) => <ReferenceLine key={`videomarker:${markerTime}`} segment={[{ x: markerTime, y: 2/5 * yMax }, { x: markerTime, y: 3/5 * yMax }]} stroke={videoMarkerColor} strokeWidth={videoMarkerWidth} label={{ value: videoMarkers[markerTime], fill: '#fff', fontSize: 14, content: ReferenceLabel }} />)} */}
              {dialMode !== 'buttonOnly' && <><Tooltip isAnimationActive={false} labelFormatter={tooltipLabel} formatter={tooltipFormatter} />
                {dataKeys.map(segment => (
                  graphType === 'line' ?
                    dialMode === 'score' ? showSegmentLine[segment] && <Line
                      key={`segmentline-${segment}`}
                      connectNulls={true}
                      dot={false}
                      type={lineType}
                      dataKey={segment}
                      stroke={lineColor[segment] || lineColor['default']}
                      strokeWidth={lineWidth[segment] || lineWidth['default']}
                      isAnimationActive={false}
                    /> : symbolKeys.map((symbol, i) => (showSegmentLine[segment][symbol.emoji] && (singleView || segmentsMap[currentSegmentGroup].includes(segment)) && <Line
                      key={`segmentline-${segment}-${symbol.emoji}`}
                      connectNulls={false}
                      dot={false}
                      type={lineType}
                      dataKey={`${segment}['${symbol.emoji}'][${symbolView}]`}
                      name={singleView ? symbol.tooltip : segment}
                      stroke={singleView ? lineColor[symbol.emoji] : lineColor[segment]}
                      strokeWidth={singleView ? lineWidth[symbol.emoji] || lineWidth['default'] : lineWidth[segment] || lineWidth['default']}
                      isAnimationActive={false}
                    />)) : symbolKeys.map((symbol, i) => (showSegmentLine[segment][symbol.emoji] && (singleView || segmentsMap[currentSegmentGroup].includes(segment)) &&
                      ((graphType === 'area' || graphType === 'area100') ? <Area
                        key={`segmentline-${segment}-${symbol.emoji}`}
                        stackId="videoTime"
                        connectNulls={false}
                        dot={false}
                        type={lineType}
                        dataKey={`${segment}['${symbol.emoji}'][${symbolView}]`}
                        name={singleView ? symbol.tooltip : segment}
                        fill={singleView ? lineColor[symbol.emoji] : lineColor[segment]}
                        fillOpacity={.8}
                        stroke={singleView ? lineColor[symbol.emoji] : lineColor[segment]}
                        strokeWidth={singleView ? lineWidth[symbol.emoji] || lineWidth['default'] : lineWidth[segment] || lineWidth['default']}
                        isAnimationActive={false}
                      /> : <Bar
                        stackId="videoTime"
                        maxBarSize={100}
                        dataKey={`${segment}['${symbol.emoji}'][${symbolView}]`}
                        name={singleView ? symbol.tooltip : segment}
                        fill={singleView ? lineColor[symbol.emoji] : lineColor[segment]}
                        stroke={'white'}
                        isAnimationActive={false}
                      />)
                    ))
                ))}</>}
              {/* {showDataLine && <Line connectNulls={false} dot={<TuneDot />} type={lineType} key={showDataLine} dataKey={showDataLine} stroke={theme.palette.respondents} strokeWidth={lineWidth['respondents']} isAnimationActive={false} />} */}
              {(refAreaLeft && refAreaRight) ? <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} /> : null}
              {/* <ZAxis zAxisId={0} type="number" dataKey="total" name="Tune Outs" domain={[0, 10]} range={[0, 500]} />
            <YAxis yAxisId={1} type="number" dataKey="index" allowDataOverflow={true} domain={[0, 100]} tick={false} tickLine={false} axisLine={false} label={{ value: 'TUNE OUTS', position: 'insideRight' }} />
            <Scatter className="scatter" yAxisId={1} zAxisId={0} dataKey="total" name="tuneOuts" fill="#8884d8" isAnimationActive={false} /> */}
            </ComposedChart>
          </ResponsiveContainer>
          {isChartViewFull && <ResponsiveContainer height="15%" minHeight="50px">
            <ScatterChart height={45} width={1100} syncId="dialChart">
              <XAxis allowDataOverflow={true} dataKey="videoTime" type="number" tick={false} axisLine={true} tickLine={false} domain={[domainMin - 0, Number.isFinite(domainMax) ? domainMax - 0 : 'auto']} interval="preserveStartEnd" />
              <YAxis type="number" dataKey="index" domain={[0, 10]} interval="preserveStartEnd" tick={false} tickLine={false} axisLine={false}  /*label={{ value: 'TUNE OUTS', position: 'insideLeft', fontSize: '75%' }}*/ />
              <ZAxis type="number" dataKey="total" domain={[0, 10]} range={[500, 500]} />
              <Tooltip wrapperStyle={{ zIndex: 1 }} isAnimationActive={false} content={buttonTooltip} cursor={false} />
              {dialMode === 'symbol' ? dataKeys.map((segment, i) => (singleView ? segment === currentSegment : (showSegmentLine[segment][currentSymbol] && segmentsMap[currentSegmentGroup].includes(segment))) && buttons[segment] && Object.entries(buttons[segment]).map(([button, data]) => (<>
                <Scatter key={`scatter-${segment}-${button}`} data={data} fill={singleView ? lineColor['tuneout'] : lineColor[segment] || lineColor['default']} isAnimationActive={false} shape={renderShapeMap[customButtons.get(button)?.shape]} onClick={showButtonReasons} />
              </>))) : dataKeys.map((segment, i) => showSegmentLine[segment] && buttons[segment] && Object.entries(buttons[segment]).map(([button, data]) => (<>
                <Scatter key={`scatter-${segment}-${button}`} data={data} fill={lineColor[segment] || lineColor['default']} isAnimationActive={false} shape={renderShapeMap[customButtons.get(button)?.shape]} onClick={showButtonReasons} />
              </>)))}
            </ScatterChart>
          </ResponsiveContainer>}
          {buttonReasonData && <Card className={classes.buttonReasonModal}>
            <CardHeader
              action={
                <IconButton onClick={() => setButtonReasonData()}>
                  <CloseIcon />
                </IconButton>
              }
              title={`${customButtons.get(buttonReasonData.button)?.label} Reasons`}
              subheader={`${formatTime(buttonReasonData.videoTime, true)} - ${buttonReasonData.dataKey.charAt(0).toUpperCase() + buttonReasonData.dataKey.slice(1)}`}
            />
            <CardContent>
              <Typography variant="body2" component="p">
                {buttonReasonData.reasons.map(reason => (
                  <span key={reason.gid}>Guest Id: {reason.gid} {reason.reason ? `- ${reason.reason}` : ''}<br /></span>
                ))}
              </Typography>
            </CardContent>
          </Card>}
          {(domainMin !== 0 || domainMax !== chartEnd) && <div className={classes.muteButton} color="inherit" onClick={zoomOut}>
            <ZoomOutIcon />
          </div>}
        </div>
      </DialChartRoot>
    )
  }

})

const renderTooltip = (props, buttonMap) => {
  const { active, payload } = props;
  // active && console.log(props)
  // if (!payload || !payload.reduce((p,c) => c.name === 'index' || p, false)) onTooltipChange?.(active ? label : 0)

  if (active && payload && payload.length) {
    const data = payload[0] && payload[0].payload;

    const btnCfg = buttonMap?.get(data.button)
    let title = btnCfg?.label ? btnCfg.label + (btnCfg?.multiPress ? btnCfg?.plural || 's' : '') : "Button Press";
    return (
      <div style={{
        margin: '0px',
        padding: '10px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgb(204, 204, 204)',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexFlow: 'column'
      }}>
        <Typography variant="overline">{title} - {data.dataKey}{data.reasons?.length ? '*' : ''}</Typography>
        <Typography>{formatTime(data.videoTime, true)} - {data.total}</Typography>
      </div >
    );
  } else return null;
}

// const CustomReferenceArea = React.memo((props) => {
//   // console.log('line', props)
//   // if (props.x === null || props.x === undefined) console.log('line', props)
//   return (
//     <line
//       x1={props.x} y1={props.y} x2={props.x} y2={props.viewBox.height + props.y} stroke={props.stroke} strokeWidth={props.strokeWidth}
//     />
//   );
// });

// const ImageReferenceArea = React.memo((props) => {
//   // console.log('line', props)
//   // if (props.x === null || props.x === undefined) console.log('line', props)
//   return (
//     <line
//       x1={props.x} y1={2 * props.viewBox.height / 5 + props.y} x2={props.x} y2={3 * props.viewBox.height / 5 + props.y} stroke={props.stroke} strokeWidth={props.strokeWidth}
//     />
//   );
// });

// const ReferenceLabel = (props) => {
//   const {
//     fill, value, textAnchor,
//     fontSize, viewBox, dy, dx,
//   } = props;
//   const x = viewBox.width + viewBox.x + 20;
//   const y = viewBox.y - 6;
//   return <text transform="rotate(90)" x={viewBox.x} y={viewBox.y} dy={dy} dx={dx} fill="#666" textAnchor="middle" dy={-6}>{`value: ${value}`}</text>;
// };

const InfoLabel = (props) => {
  const { value, fontSize, viewBox, currentSegment, currentSegmentGroup, currentSymbol, dialMode, singleView } = props;
  return <>
    {dialMode === 'symbol' && <Text
      dy={`${value.length * 1.2 + 0.3}em`}
      x={viewBox.x + 10}
      y={viewBox.y + 10}
      fontSize={fontSize || 10}
      fontFamily='Roboto, sans-serif'
      fill={'black'}>
      {singleView ? `Segment: ${currentSegment}` :
        `Segment Group: ${currentSegmentGroup === 'all' ? 'Total' : currentSegmentGroup} | Symbol: ${currentSymbol}`
      }
    </Text>}
    {value.map((info, i) =>
      <Text
        key={`infoText-${i}`}
        // width={viewBox.height / 2}
        // height={viewBox.width}
        textAnchor="start"
        verticalAnchor="end"
        x={viewBox.x + 10}
        y={viewBox.y + 10}
        dy={`${i * 1.2 + 0.3}em`}
        // dx={dx}
        fill={info.color}
        fontSize={fontSize || 10}
        fontFamily='Roboto, sans-serif'
      // scaleToFit={true}
      >
        {`${info.label} ${info.count} | ${info.total} | ${info.total}`}
      </Text>
    )}</>;
}

const ReferenceLabel = (props) => {
  console.log('marker', props)
  const {
    fill, value,
    fontSize, viewBox, dy, dx,
  } = props;
  return (
    <Text
      width={5 * viewBox.height / 2}
      height={5 * viewBox.width}
      angle={90}
      textAnchor="start"
      verticalAnchor="end"
      x={viewBox.x + 5}
      y={5 * viewBox.height / 2 + 10}
      dy={dy}
      dx={dx}
      fill={fill}
      fontSize={fontSize || 10}
      fontFamily='Roboto, sans-serif'
    // scaleToFit={true}
    >
      {value}
    </Text>
  )
}